import {useYupForm} from "src/packages/react-hook-form-mui-yup-helpers/yup-form-utils";
import {YupFormProvider} from "src/packages/react-hook-form-mui-yup-helpers/yup-form-provider";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {useFormContext} from "react-hook-form";
import React, {useEffect, useRef} from "react";
import {FormErrorMessage, FormSubmitButton} from "src/packages/react-hook-form-mui-form-fields";
import makeStyles from '@mui/styles/makeStyles';
import {useDispatch} from "react-redux";
import {addUnloadBlocker, removeUnloadBlocker} from "src/features/ui/unload-blocker";
import {ProgressButton} from "src/packages/react-hook-form-mui-form-fields/submit-button-view";
import {ValidationError as ApiValidationError} from "src/api/api";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  deleteButtonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    color: theme.palette.warning.main,
  },
  spacer: {
    flex: 1,
  },
}));

function SimpleYupFormContainer({schema, data, errors, submit, children}) {
  const {form, submitAction} = useYupForm({schema, data, errors, submit});

  return (
    <YupFormProvider schema={schema} form={form}>
      <form onSubmit={submitAction}>
        {children}
      </form>
    </YupFormProvider>
  );
}

export function SimpleYupFormDialogImplementation(
  {
    open,
    onClose,
    id,
    title,
    children,
    isDeleting,
    onDelete,
    deleteCaption = "Löschen",
    saveCaption = "Speichern",
    cancelCaption = "Nicht speichern",
    defaultErrorMessage,
    additionalButtons,
    ...props
  },
) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const {
    formState: {errors, isDirty, isSubmitting},
  } = useFormContext();
  const hasErrors = errors && Object.keys(errors).length > 0;

  const ref = useRef();

  // TODO: Focus fields on error. Currently, this does not work since fields are disabled during submit, therefore not
  //   focusable.

  return (
    <div ref={ref}>
      <Dialog
        open={open}
        onClose={onClose}
        disableEscapeKeyDown={isDirty}
        aria-labelledby={id}
        fullScreen={fullScreen}
        container={ref.current}
        style={{whiteSpace: 'normal'}}
        {...props}>
        <DialogTitle id={id}>{title}</DialogTitle>

        {children}

        <Collapse in={hasErrors}>
          <DialogContent>
            <FormErrorMessage defaultErrorMessage={defaultErrorMessage} renderEmpty/>
          </DialogContent>
        </Collapse>

        <DialogActions>
          {onDelete ? (
            <>
              <ProgressButton
                onClick={onDelete}
                disabled={isSubmitting || isDeleting}
                inProgress={isDeleting}
                className={classes.deleteButtonWrapper}
              >
                {deleteCaption}
              </ProgressButton>
              <div className={classes.spacer}/>
            </>
          ) : null}
          <Button
            color="inherit"
            onClick={onClose}
            disabled={isSubmitting || isDeleting}
          >
            {cancelCaption}
          </Button>
          {additionalButtons}
          <FormSubmitButton color="primary" variant="contained" disabled={isSubmitting || isDeleting}>
            {saveCaption}
          </FormSubmitButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export class ValidationError extends Error {
  constructor(data) {
    super("ValidationError");
    this.data = data;
    this.name = "ValidationError";
  }
}

export function SimpleYupFormDialog(
  {
    id,
    open,
    onClose,
    data,
    submit,
    schema,
    disableCloseAfterSubmit,
    onDelete,
    deleteConfirmation,
    allowDelete,
    disableUnloadBlocker,
    ContainerComponent=SimpleYupFormContainer,
    DialogImplementationComponent=SimpleYupFormDialogImplementation,
    containerComponentProps={},
    ...props
  },
) {
  const [errors, setErrors] = React.useState(undefined);

  const dispatch = useDispatch();

  // TODO: Move into Sendemeldung-internal component due to dependence on unload blocker.
  useEffect(() => {
    if (!disableUnloadBlocker) {
      const DIALOG_BLOCKER = {type: 'dialog', id: 'simpleYupFormDialog'};
      dispatch(addUnloadBlocker(DIALOG_BLOCKER));
      return () => {
        dispatch(removeUnloadBlocker(DIALOG_BLOCKER));
      }
    }
  });

  if (!allowDelete) {
    onDelete = undefined;
  }

  if (deleteConfirmation && onDelete) {
    const baseOnDelete = onDelete;
    onDelete = (...args) => {
      return confirm(deleteConfirmation) && baseOnDelete(...args);
    }
  }

  return (
    <ContainerComponent
      schema={schema}
      data={data}
      errors={errors}
      submit={async (validatedData) => {
        let result;
        try {
          result = await submit(validatedData);
        } catch (e) {
          if (e instanceof ValidationError || e instanceof ApiValidationError) {
            let data = e.data;

            if (_.isArray(data)) {
              data = {non_field_errors: data?.join(' ')};
            }

            setErrors(data);
          } else {
            setErrors({non_field_errors: [e.toString()]});
          }
          return;
        }
        if (!disableCloseAfterSubmit) {
          onClose && onClose();
        }
        return result;
      }}
      {...containerComponentProps}
    >
      <DialogImplementationComponent
        id={id}
        open={open}
        onClose={onClose}
        onDelete={onDelete ? (
          async (...args) => {
            let result;
            try {
              result = await onDelete(...args);
            } catch (e) {
              if (e instanceof ValidationError || e instanceof ApiValidationError) {
                let data = e.data;

                if (_.isArray(data)) {
                  data = {non_field_errors: data?.join(' ')};
                }

                setErrors(data);
              } else {
                setErrors({non_field_errors: [e.toString()]});
              }
              return;
            }
            if (result) {
              onClose && onClose();
            }
          }
        ) : null}
        {...props}
      />
    </ContainerComponent>
  );
}
