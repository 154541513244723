import {navigate} from "@gatsbyjs/reach-router";
import {useQuery} from "src/packages/route-utils";
import {useCallback, useEffect, useMemo} from "react";
import {deserializeFilterValue, serializeFilterValue} from "src/packages/FancyFilterField";
import {useDispatch, useSelector} from "react-redux";
import {
  getInvisibleCreatedIdsByOrganizationForListing,
  getListing,
  getVisibleIdsForListing,
  registerListingObserver,
  unregisterListingObserver,
  updateListing
} from "src/features/ui/listing/listing-slice";
import {getSelectedOrganizationId} from "src/features/dashboard";
import {debounce} from "lodash";

export function updateQueryString(kwargs, options) {
  const searchParams = new URLSearchParams(window.location.search);

  Object.entries(kwargs)
    .forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

  const searchString = `?${searchParams.toString()}`;
  if (searchString !== window.location.search) {
    navigate(searchString, options);
  }
}

const emptyArray = [];

export const useFilter = (props) => {
  const paramName = props?.paramName || 'filter';
  const resetParams = props?.resetParams || emptyArray;
  const queryFilter = useQuery(paramName) || '';
  const filter = useMemo(() => deserializeFilterValue(queryFilter), [queryFilter]);

  const updateFilter = useCallback((event, value, options) => updateQueryString(
    {
      [paramName]: serializeFilterValue(value),
      ...Object.fromEntries(resetParams.map((param) => [param, undefined])),
    },
    {replace: true, ...options},
  ), [paramName, resetParams]);

  return useMemo(() => (
    [filter, updateFilter]
  ), [filter, updateFilter]);
};

export const useListing = (
  {
    listingId,
    defaultPageSize = 10,
    pageSizeParamName = 'pageSize',
    defaultPage = 1,
    pageParamName = 'page',
    defaultOrdering = emptyArray,
    orderingParamName = 'ordering',
    filterParamName = 'filter',
    endpoint,
    entityType,
    delay = 300,
    cleanupTimeout = 5000,
    filter,
    updateFilter,
    meta,
  }
) => {
  const listing = useSelector(getListing)(listingId);
  const {meta: currentMeta} = listing;

  const queryPageSize = useQuery(pageSizeParamName);
  let pageSize;
  try {
    pageSize = parseInt(queryPageSize) || defaultPageSize;
  } catch (e) {
    pageSize = defaultPageSize;
  }

  const queryPage = useQuery(pageParamName);
  let page;
  try {
    page = parseInt(queryPage) || defaultPage;
  } catch (e) {
    page = defaultPage;
  }

  const queryOrdering = useQuery(orderingParamName);

  const ordering = useMemo(() => {
    const ordering = listing.ordering;
    if (queryOrdering) {
      if (ordering?.length !== 1 || ordering[0] !== queryOrdering) {
        return [queryOrdering];
      }
    } else if (!ordering) {
      return defaultOrdering;
    }
    return ordering;
  }, [listing.ordering, queryOrdering, JSON.stringify(defaultOrdering)]);

  const resetParams = useMemo(() => ([pageParamName]), [pageParamName]);
  const paramFilter = useFilter({
    paramName: filterParamName,
    resetParams
  });

  if (filter === undefined) {
    filter = paramFilter[0];
    updateFilter = paramFilter[1];
  }

  const dispatch = useDispatch();
  const debouncedDispatch = useMemo(() => {
    return debounce(dispatch, delay);
  }, [dispatch, delay]);

  const manageObservation = useMemo(() => {
    const registeredObservers = {};
    const manageObservationFn = ({
      listingId,
      observed,
    }) => {
      if (registeredObservers[listingId] === undefined) {
        registeredObservers[listingId] = false;
      }

      if (registeredObservers[listingId] !== observed) {
        if (observed) {
          dispatch(registerListingObserver({id: listingId}));
        } else {
          dispatch(unregisterListingObserver({id: listingId}));
        }
        registeredObservers[listingId] = observed;
      }
    };

    return debounce(manageObservationFn, cleanupTimeout);
  }, [dispatch, cleanupTimeout]);

  useEffect(() => {
      if (listingId && endpoint && entityType) {
        manageObservation({
          listingId,
          observed: true
        });
        manageObservation.flush();

        let newMeta;
        if (filter) {
          const metaFilters = {};
          filter.forEach(({
            id,
            choice
          }) => {
            metaFilters[id] = choice;
          });
          newMeta = {
            ...currentMeta,
            ...meta,
            filters: metaFilters
          };
        } else {
          newMeta = {
            ...currentMeta,
            ...meta,
          };
        }

        debouncedDispatch(updateListing({
          id: listingId,
          meta: newMeta,
          currentPage: page,
          pageSize,
          ordering,
          searchQuery: '',
          endpoint,
          entityType,
        }));
      } else if (listingId) {
        // TODO: Clear listing (and/or do that in unmount effect).
        manageObservation({
          listingId,
          observed: false
        });
      }

      return () => manageObservation({
        listingId,
        observed: false
      });
    },
    [
      listingId,
      JSON.stringify(filter),
      pageSize,
      page,
      ordering,
      endpoint,
      entityType,
      debouncedDispatch,
      manageObservation,
      JSON.stringify(meta),  // convert to string so that semantically identical meta objects do not trigger the effect
    ],
  );

  const visibleIds = useSelector(getVisibleIdsForListing)(listingId);

  const {count} = listing;

  return useMemo(() => ({
    listingId,
    listing,
    count,
    pageSize,
    ordering,
    visibleIds,
    renderIds: visibleIds,
    filter,
    updateFilter,
    filterProps: {
      value: filter,
      onChange: updateFilter
    },
    paginationProps: {
      listingId,
      setPageSize: (pageSize) => {
        updateQueryString({
          [pageSizeParamName]: pageSize,
          [pageParamName]: 1
        }, {replace: true});
      },
      onSetPage: (page) => {
        updateQueryString({[pageParamName]: page});
      },
      ...(filter.length > 0 ? {force: true} : {}),
    },
    isLoading: listing.count === undefined,
    noDataExists: listing.count === 0 && filter.length === 0,
  }), [
    listingId,
    listing,
    count,
    pageSize,
    ordering,
    visibleIds,
    JSON.stringify(filter),
    updateFilter,
    pageSizeParamName,
    pageParamName,
  ]);
};

export const useOrganizationRelatedListing = ({
  organizationId,
  listingIdSuffix,
  endpoint,
  reverseCreatedIds = false,
  ...props
}) => {
  const selectedOrganizationId = useSelector(getSelectedOrganizationId);
  if (!organizationId) {
    organizationId = selectedOrganizationId;
  }

  const listingId = `${organizationId}/${listingIdSuffix}`;

  if (organizationId) {
    endpoint = endpoint.replace('[ORGANIZATION_ID]', organizationId);
  } else {
    endpoint = undefined;
  }

  const listing = useListing({
    listingId,
    endpoint,
    ...props,
  });

  const createdIds = useSelector(getInvisibleCreatedIdsByOrganizationForListing)(listingId)(organizationId);
  const orderedCreatedIds = useMemo(() => {
    let orderedCreatedIds = createdIds;
    if (reverseCreatedIds) {
      orderedCreatedIds = [...orderedCreatedIds];
      orderedCreatedIds.reverse();
    }
    return orderedCreatedIds;
  }, [createdIds, reverseCreatedIds]);

  const renderIds = useMemo(() => (
    reverseCreatedIds ? [...orderedCreatedIds, ...listing.visibleIds] : [...listing.visibleIds, ...orderedCreatedIds]
  ), [listing.visibleIds, orderedCreatedIds, reverseCreatedIds]);

  return useMemo(() => ({
    ...listing,
    organizationId,
    createdIds: orderedCreatedIds,
    renderIds,
  }), [listing, orderedCreatedIds, renderIds]);
};
