import {all, call, delay, put, race, select, take, takeEvery} from 'redux-saga/effects';
import axios from 'axios';
import {normalize} from 'normalizr';
import {ORGANIZATIONS, UPLOADED_FILE} from "src/api/api-schemas";
import {setEntities} from "src/features/entity";
import {
  getOrganizationIds,
  getSelectedOrganizationId,
  getVersion,
  selectOrganization,
  setBackendVersion,
  setOrganizations
} from "src/features/dashboard/dashboard-slice";
import {getSessionData, loginSuccess, logout, logoutSuccess} from '../session';
import {uploadedFile, uploadFailed, uploadFile} from "./dashboard-slice";

function* fetchOrganizations() {
  while (true) {
    try {
      let cursor = undefined;
      while (cursor || cursor === undefined) {
        let url = `/api/sendemeldung/organizations/`;
        if (cursor) {
          url += `?cursor=${encodeURIComponent(cursor)}`;
        }
        const response = yield call(axios.get, url);
        if (!response.data) {
          // noinspection ExceptionCaughtLocallyJS
          throw new Error("fetch organizations failed");
        }

        cursor = response.data?.next;

        const organizations = response.data?.results;
        const normalizedOrganizations = normalize(organizations, ORGANIZATIONS);

        yield put(setEntities(normalizedOrganizations));
        yield put(setOrganizations({organizations: normalizedOrganizations.result}));
      }

      const organizationIds = yield select(getOrganizationIds);
      if (organizationIds?.length > 0) {
        const selectedOrganizationId = yield select(getSelectedOrganizationId);
        if (!organizationIds?.includes(selectedOrganizationId)) {
          yield put(selectOrganization({organization: organizationIds[0]}));
        }
      }

      break;
    } catch (e) {
      console.error(e);
      if (e.isAxiosError && e.response?.status === 401) {
        yield put(logout());
        break;
      }
      yield delay(500);
    }
  }
}

function* dataSaga() {
  while (true) {
    // Wait for session data to become available.
    let active;
    while (!active) {
      const sessionData = yield select(getSessionData);
      active = sessionData.active;

      if (!active) {
        // Wait for login or state rehydration.
        yield take([loginSuccess]);
      }
    }

    const raceResult = yield race({
      fetchOrganizations: call(fetchOrganizations),
      logoutTimeout: take([logoutSuccess]),
      selectOrganizationTimeout: take([selectOrganization]),
      fetchTimeout: delay(60000),
    });

    if (raceResult?.logoutTimeout || raceResult?.selectOrganizationTimeout) {
      continue;
    }

    // Start over after logout or when another organization is selected (so that we refresh the list of organizations).
    yield take([logoutSuccess, selectOrganization]);
  }
}

function* versionWatcherSaga() {
  let version = yield select(getVersion);
  while (true) {
    const action = yield take([setBackendVersion]);
    const latestVersion = action?.payload?.version;

    if (version !== latestVersion) {
      console.log("Refreshing UI due to version update.");
      window.location.reload(true);
    }

    version = latestVersion;
  }
}

function* watchUploadFile(action) {
  const {internalId, name, size, blob} = action.payload;

  let data = new FormData();
  data.append('name', name);
  data.append('file', blob);

  for (let i = 0; i < 4; i++) {
    let response;
    try {
      const organizationId = yield select(getSelectedOrganizationId);

      // yield delay(2000);

      response = yield call(axios.post, `/api/sendemeldung/organizations/${organizationId}/uploaded_files/`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (e) {
      console.error(e);
      yield delay(i * 1000);
      continue;
    }

    if (response.status === 201) {
      yield put(uploadedFile({internalId, name, size, ...response.data}));

      const uploadedFileData = response.data;
      const normalizedUploadedFile = normalize(uploadedFileData, UPLOADED_FILE);

      yield put(setEntities({
        ...normalizedUploadedFile,
        created: true,
        createdForOrganization: uploadedFileData.organization,
      }));
      return;
    } else {
      yield delay(i * 1000);
    }
  }

  yield put(uploadFailed({internalId}));
}


export default function* rootSaga() {
  yield takeEvery(uploadFile, watchUploadFile);

  yield all([
    call(dataSaga),
    call(versionWatcherSaga),
  ]);
}
