import orm from "./orm";

const ORM_MAP = {
  'organizations': 'Organization',
  'contacts': 'Contact',
  'contact_roles': 'OrganizationContactRole',
  'stations': 'Station',
  'gemagvl4_stations': 'GEMAGVL4Station',
  'uploaded_files': 'UploadedFile',
  'gemagvl4_lieferungen': 'GEMAGVL4Lieferung',
  'gemagvl4_ausstrahlungen': 'GEMAGVL4Ausstrahlung',
  'erschienene_tonaufnahmen': 'ErschieneneTonaufnahme',
  'org_music_works': 'OrgMusicWork',
  'org_music_work_log': 'OrgMusicWorkLog',
  'org_music_work_logs': 'OrgMusicWorkLogs',
  'musik_personen': 'MusikPerson',
  'musik_produktion_ids': 'MusikProduktionID',
  'gemagvlxml_lieferungen': 'GEMAGVLXMLLieferung',
  'gemagvlxml_ausstrahlungen': 'GEMAGVLXMLAusstrahlung',
  'user_feedbacks': 'UserFeedback',
  'known_bugs': 'KnownBug',
  'documents': 'Document',
  'notifications': 'Notification',
  'todo_tasks': 'TodoTask',
  'gvl_products': 'GVLProduct',
  'gema_works': 'GEMAWork',
  'databases': 'Database',
  'database_rights': 'DatabaseRight',
  'lookup_tables': 'LookupTable',
};

const SORT_ORDERS = {
  contact_roles: 'contacts',
  contacts: 'organizations',
};

export function ormReducer(dbState, action) {
  const sess = orm.session(dbState);

  if (action.type === 'entities/setEntities') {
    Object.entries(action.payload?.entities).sort(
      (entry1, entry2) => {
        const a = entry1[0],
          b = entry2[0];

        if (SORT_ORDERS[a] === b) {
          return -1;
        } else if (SORT_ORDERS[b] === a) {
          return 1;
        }
        return 0;
      },
    ).forEach(([entityType, entities]) => {
      const modelName = ORM_MAP[entityType];
      if (modelName === undefined) {
        console.warn(`missing model in ORM_MAP: ${entityType}`);
        return;
      }
      const model = sess[modelName];
      if (model === undefined) {
        console.warn(`unregistered model: ${entityType}`);
        return;
      }


      Object.entries(entities).forEach(([id, entity]) => {
        if (entity === null) {
          model.withId(id)?.delete();
        } else {
          model.upsert(entity);
        }
      });
    });
  }

  return sess.state;
}
