import {
  Button,
  Chip,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import React from "react";
import {Dns, KeyboardArrowDown, MonetizationOn, Radio} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedOrganization, getSelectedOrganizationId, selectOrganization} from "src/features/dashboard";
import makeStyles from '@mui/styles/makeStyles';
import {navigate} from "gatsby";
import {selectOrganizations} from "src/features/orm/selectors";
import {getOrganizationGetter} from "src/features/entity";
import {useEntityObserver} from "src/features/entity/entity-hooks";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   marginLeft: -theme.spacing(2),
  // },
  buttonTitle: {
    textTransform: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
}));

function OrganizationMenuItemContent({id}) {
  const data = useSelector(getOrganizationGetter)(id);
  const {
    display_name,
    company_name,
    customer_id,
    is_gemagvl_approved: isApproved,
    has_published_reports: hasPublished,
    requires_test: requiresTest,
    type,
  } = data;

  useEntityObserver({type: 'organization', id});

  let badge = null;

  if (!isApproved) {
    if (hasPublished) {
      badge = (
        <>
          {' '}
          <Chip size="small" label="warte auf Freigabe"/>
        </>
      );
    } else if (requiresTest) {
      badge = (
        <>
          {' '}
          <Chip size="small" label="Test erforderlich" color="primary"/>
        </>
      );
    }
  }

  return (
    <>
      <ListItemIcon>
        {type === 'HF' ? (
          <Radio/>
        ) : type === 'VWG' ? (
          <MonetizationOn/>
        ) : type === 'PRV' ? (
          <Dns/>
        ) : null}
      </ListItemIcon>
      {display_name ? (
        <>
          <ListItemText
            primary={(
              <>
                {display_name}
                <span style={{visibility: 'hidden'}}>{badge}</span>
              </>
            )}
            secondary={(<>
              {customer_id ? (<>#{customer_id}{' '}&ndash;{' '}</>) : null}
              {company_name}
            </>)}
          />
        </>
      ) : company_name ? (
        <ListItemText
          primary={(
            <>
              {company_name}
              <span style={{visibility: 'hidden'}}>{badge}</span>
            </>
          )}
          secondary={(<>
            {customer_id ? (<>#{customer_id}</>) : null}
          </>)}
        />
      ) : (
        id
      )}

      {!isApproved ? (
        <ListItemSecondaryAction>
          {badge}
        </ListItemSecondaryAction>
      ) : null}
    </>
  );
}

export default function OrganizationChoiceButton({title}) {
  const classes = useStyles();

  const selectedOrganizationId = useSelector(getSelectedOrganizationId);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();
  const handleSelect = (organization) => {
    dispatch(selectOrganization({organization}));
    closeMenu();

    // Quick hack: If the path contains a UUID (identified by '-'), redirect to start page when changing the
    //   organization to prevent displaying objects from other organizations.
    if (organization !== selectedOrganizationId && window?.location?.href?.includes('-')) {
      navigate('/dashboard/');
    }
  };

  const selectedOrganization = useSelector(getSelectedOrganization);

  const organizations = useSelector(selectOrganizations);

  return (
    <>
      <Button
        aria-label="account of current user"
        aria-controls="organization-choice"
        aria-haspopup="true"
        onClick={openMenu}
        color="inherit"
        endIcon={<KeyboardArrowDown/>}
        className={classes.root}
        sx={{ml: -1}}
      >
        <Typography variant="h6" className={classes.buttonTitle}>
          {title}
        </Typography>
      </Button>
      <Menu
        id="organization-choice-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={closeMenu}
      >
        {!open ? null : organizations.filter((organization) => organization?.databases).map((organization, i) => (
          <MenuItem
            key={organization?.id || i}
            selected={organization?.id === selectedOrganization?.id}
            onClick={() => handleSelect(organization?.id)}
          >
            <OrganizationMenuItemContent id={organization?.id}/>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
