import {Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormGroup, FormHelperText} from "@mui/material";
import {Controller} from "react-hook-form";
import React from "react";
import _ from "lodash";

export default function Checkbox({errors = {}, control, name, helperText, ...props}) {
  const error = _.get(errors, name);

  return (
    <FormControl error={!!error} component="fieldset">
      <FormGroup row>
        <FormControlLabel
          control={(
            <Controller
              name={name}
              control={control}
              defaultValue={false}
              render={({field: {onChange, value, ...otherProps}}) => (
                <MuiCheckbox
                  {...props}
                  {...otherProps}
                  checked={value === true}
                  onChange={(e) => onChange(e.target.checked)}
                  color="primary"
                />
              )}
            />
          )}
          {...props}
        />
        {error ? <FormHelperText>{error.message}</FormHelperText> : null}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormGroup>
    </FormControl>
  );
}
