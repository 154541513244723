import React from "react";
import _ from "lodash";
import {FormControl, FormHelperText} from "@mui/material";
import {Controller} from "react-hook-form";
import {DateTimePicker} from '@mui/x-date-pickers';
import {DateTime} from "luxon";

export default function DateTimeView(
  {
    errors = {},
    control,
    name,
    variant = "standard",
    fullWidth = true,
    label,
    items = {},
    margin,
    onBlur,
    ...props
  },
) {
  const error = _.get(errors, name);

  return (
    <FormControl
      fullWidth={fullWidth}
      margin={margin}
      error={!!error}
      onBlur={onBlur}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        render={({field: {onChange, value, ...field}}) => {
          let parsedValue;
          try {
            parsedValue = DateTime.fromISO(value, {setZone: true});
            if (parsedValue?.invalid) {
              parsedValue = undefined;
            }
          } catch(e) {
            parsedValue = undefined;
          }

          return (
            <DateTimePicker
              label={label}
              inputProps={{
                id: name,
              }}
              ampm={false}
              format="yyyy-MM-dd HH:mm:ss"
              cancelText="Abbrechen"
              mask="____-__-__ __:__:__"
              views={["year", "month", "day", "hours", "minutes", "seconds"]}
              slotProps={{textField: {variant}}}
              timeSteps={{hours: 1, minutes: 1, seconds: 1}}
              timezone={parsedValue?.zoneName || "system"}
              // renderInput={(props) =>
              //   <TextField
              //     variant={variant}
              //     {...props}
              //   />}
              onChange={(date, value) => {
                try {
                  date = date.toISO({suppressMilliseconds: true});
                } catch (e) {
                  // value = null;
                }
                return onChange(date, value);
              }}
              {...props}
              defaultValue={parsedValue}
              {...field}
            />
          );
        }}
      />
      {error ? <FormHelperText>{error.message}</FormHelperText> : null}
    </FormControl>
  );
}
